angular.module("app")
    .factory("_weatherRowDisplayStateHolder", function(_appStorage) {
        var state = _load();

        function isActive(id) {
            var answer = state[id];

            if (_.isUndefined(answer)) {
                return true;
            } else {
                return answer;
            }
        }

        function toggle(id) {
            state[id] = !isActive(id);
            _persist();
        }

        function  _load() {
            return _appStorage.get("weatherItemDisplayState") || {};
        }

        function _persist() {
            _appStorage.set("weatherItemDisplayState", state);
        }

        return {
            isActive: isActive,
            toggle: toggle
        }
    })
    .factory("_weather", function($timeout, _calendar, _weatherRowDisplayStateHolder, _log) {
        function get(field, from, granularity) {
            //_log.debug("Loading weather data for field:" + field + ", from:" + from + ", granularity:" + granularity);
            return $timeout(function() {
                var columns = [];
                return columns;
            }, 1000);
        }

        var rows = (function getItems() {
            return _.map(["w", "sr", "ws", "at", "h", "e", "ct", "dpt", "lw", "st", "rf"], function(id) {
                return {
                    id: id,
                    isActive: _weatherRowDisplayStateHolder.isActive(id)
                }
            })
        })();

        function rowToggle(id) {
            var item = rowFindById(id);

            _weatherRowDisplayStateHolder.toggle(id);
            item.isActive = !item.isActive;
        }

        function rowFindById(id) {
            return _.find(rows, function(item) {
                return item.id == id;
            })
        }

        return {
            get: get,
            rows: {
                toggle: rowToggle,
                get: function() {
                    return rows;
                }
            }
        }
    });